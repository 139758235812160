export const SOCIAL_LIST = [
    {
        id: 1,
        iconClass: "fa fa-facebook",
        link: "https://www.facebook.com/prashant.dawkhar.50"
    },{
        id: 2,
        iconClass: "fa fa-envelope",
        link: "mailto:prashantdawkhar02@gmail.com"
    },{
        id: 3,
        iconClass: "fa fa-linkedin",
        link: "https://www.linkedin.com/in/prashant-dawkhar-20400a12a"
    },
]
