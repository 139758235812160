export const NAVBAR_LIST = [
    {
        id: "HOME",
        name: "Home",
    },
    {
        id: "ABOUT_US",
        name: "About Us",
    },
    {
        id: "SERVICES",
        name: "Services",
    },
    {
        id: "TIMELINE",
        name: "Timeline",
    },
    {
        id: "WORK",
        name: "Work",
    },
    {
        id: "CONTACT_US",
        name: "Contact",
    }
];