export const SKILLS_LIST = [
    {
        id: 1,
        label: "HTML, CSS & JAVASCRIPT",
        value: 85
    },
    {
        id: 2,
        label: "REACT",
        value: 85
    }, {
        id: 3,
        label: "Next",
        value: 75
    }, {
        id: 4,
        label: "TYPESCRIPT",
        value: 82
    },
]
